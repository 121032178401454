import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { User, TableColumn } from './user.types';
import { getUsers, getRoles, downloadUsers } from 'network/user-management';
import {
    Button,
    Table,
    Input,
    Space,
    Modal,
    Select,
    message,
    Tag,
} from 'antd';
import { userColumns } from './user.constants';
import { userManagementStyles } from './user-management.styles';
import {
    EditFilled,
    DownloadOutlined,
    FilterOutlined,
    PlusOutlined,
    LeftOutlined,
    RightOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ReduxStore } from 'reducers/redux.types';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import AddUser from './AddUser';
import UserDetailsModal from './UserDetailModal';
import { debounce } from 'lodash';
import Helper from '../../library/Helper';

const { Option } = Select;
interface UserMappingProps extends StylesProps<ReturnType<typeof userManagementStyles>> {
    uiTheme: ThemeType,
    password_policy: any,
    child_customers: any,
}

const UserMapping: React.FC<UserMappingProps> = (props) => {
    const {
        classes, uiTheme, password_policy, child_customers,
    } = props;
    const { t } = useTranslation();
    const [users, setUsers] = React.useState<User[]>([]);
    const [searchText, setSearchText] = React.useState<string>('');
    const [isAddUserModalVisible, setIsAddUserModalVisible] = React.useState<boolean>(false);
    const [isFilterModalVisible, setIsFilterModalVisible] = React.useState<boolean>(false);
    const [selectedCity, setSelectedCity] = React.useState<string | undefined>(undefined);
    const [selectedStatus, setSelectedStatus] = React.useState<string | undefined>(undefined);
    const [selectedPhone, setSelectedPhone] = React.useState<string | undefined>(undefined);
    const [editData, setEditData] = React.useState<User | null>(null);
    const [selectedUser, setSelectedUser] = React.useState<User | null>(null); // State for selected user
    const [isUserDetailsModalVisible, setIsUserDetailsModalVisible] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string | null>(null);
    const [userRoleList, setUserRoleList] = React.useState<{ id: string; value: string }[]>([]);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [isNextPage, setIsNextPage] = React.useState<boolean>(false);
    // const [rolesList, setRolesList] = React.useState<{ id: string; value: string }[]>([]);
    const [dataChanged, setDataChanged] = React.useState<boolean>(false);
    const [downloading, setDownloading] = React.useState<boolean>(false);
    const [userPermissionsList, setUserPermissionsList] = React.useState<{ [key: string]: string[] }>({});
    // const pageSize = 20;

    const fetchData = async (params = {}) => {
        setLoading(true);
        setError(null);
        const usersData = await getUsers(params); // { username: '' }
        if (!usersData.isSuccess) {
            message.error(usersData.errorMessage);
        } else {
            setUsers(usersData?.data?.customerUsers || []);
            if (params?.pageNum) {
                setCurrentPage(Number(params?.pageNum));
            } else {
                setCurrentPage(1);
            }
            // setCurrentPage(usersData?.data?.page);
            setIsNextPage(usersData?.data.isNextPageExists || false);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        fetchData();
    }, [dataChanged]); // Include any dependencies that should trigger a re-fetch
    interface UserRole {
        id: string;
        value: string;
    }
    const handleDownload = async () => {
        setDownloading(true);
        const response = await downloadUsers();
        if (response.isSuccess) {
            Helper.downloadFileData(
                response.data,
                'UserDetails.csv',
                true,
            );
            message.success('Downloaded Successfully');
        } else {
            message.error(response.errorMessage);
        }
        setDownloading(false);
    };
    const fetchUserRolesedit = async (userId: number) => {
        const rolesData = await getRoles(userId);
        if (rolesData.isSuccess) {
            const roles_List = rolesData.data.userRoleGroupArr[0].rolesList || [];
            const userRoles: UserRole[] = roles_List.map((role: { role_id: string; role_name: string }) => ({
                id: role.role_id,
                value: role.role_name,
            }));
            setUserRoleList(userRoles);
        } else {
            message.error(rolesData.errorMessage);
        }
        const usersData = await getUsers(); ///
        if (usersData.isSuccess) {
            // setRolesList(usersData?.data?.userRoleList || []);
            setUserPermissionsList(usersData?.data?.userPermssionList || []);
        }
        setLoading(false);
    };
    const handleFilterApply = async () => {
        const filters: Record<string, string | undefined> = {
            city: selectedCity?.toUpperCase(),
            username: searchText,
            status: selectedStatus,
            pageNum: currentPage.toString(),
            phoneNum: selectedPhone,
        };
        // Filter out undefined or empty values
        const filteredFilters = Object.fromEntries(
            Object.entries(filters).filter(([, value]) => value !== undefined && value !== ''),
        );
        // Check if any of the filters (except pageNum) is not empty
        const {
            city,
            username,
            status,
            phoneNum,
        } = filteredFilters;
        const hasOtherFilters = [city, username, status, phoneNum].some((filter) => filter !== undefined && filter !== '');
        // If there are other filters, set pageNum to '1'
        if (hasOtherFilters) {
            filteredFilters.pageNum = '1';
            setCurrentPage(1);
        }
        // Call fetchData with the filtered filters
        await fetchData(filteredFilters);
        setIsFilterModalVisible(false);
    };
    React.useEffect(() => {
        handleFilterApply();
    }, [currentPage]);
    const renderPagination = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {/* Left Arrow */}
                <LeftOutlined
                    style={{
                        fontSize: '15px',
                        cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                        margin: '0 0px',
                    }}
                    onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
                />

                <div
                    style={{
                        padding: '5px 15px',
                        border: '1px solid #d9d9d9',
                        borderRadius: '4px',
                        margin: '0 10px',
                        textAlign: 'center',
                        fontSize: '12px',
                        width: '35px',
                    }}
                >
                    {currentPage}
                </div>

                {/* Right Arrow */}
                <RightOutlined
                    style={{
                        fontSize: '15px',
                        cursor: isNextPage ? 'pointer' : 'not-allowed',
                        margin: '0 0px',
                    }}
                    onClick={() => isNextPage && setCurrentPage(currentPage + 1)}
                />
            </div>
        );
    };
    const debouncedFilterApply = debounce(async () => {
        await handleFilterApply();
    }, 1000);
    React.useEffect(() => {
        debouncedFilterApply();
        // Cleanup the debounce function on unmount
        return () => {
            debouncedFilterApply.cancel();
        };
    }, [searchText]);

    const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchText(value);
        await debouncedFilterApply();
    };
    const renderTitle = () => {
        return (
            <div className={classes.title}>
                <div className={classes.leftSection}>
                    <Space>
                        <span style={{ fontWeight: 'normal', fontSize: 'small' }}> User Code </span>
                        <Input
                            placeholder={t('Search for user code')}
                            value={searchText}
                            // onChange={(e) => setSearchText(e.target.value)}
                            // onKeyPress={async (e) => {
                            //     if (e.key === 'Enter') {
                            //         await handleFilterApply();
                            //     }
                            // }}
                            // onChange={handleInputChange}
                            onChange={handleInputChange}
                            style={{ width: 200, borderRadius: '20px' }}
                        />
                        <Button
                            type="primary"
                            onClick={() => setIsFilterModalVisible(true)}
                            icon={<FilterOutlined />}
                            style={{
                                backgroundColor: 'white',
                                borderColor: 'white',
                                color: 'black',
                                boxShadow: 'none',
                                fontSize: 'small',
                            }}
                        >
                            {t('More filter')}
                        </Button>
                    </Space>
                </div>
                <div className={classes.rightSection}>
                    <Space>
                        <Button
                            type="ghost"
                            loading={downloading}
                            onClick={handleDownload}
                            icon={<DownloadOutlined />}
                        >
                            {t('download')}
                        </Button>
                        <Button
                            type="primary"
                            onClick={async () => {
                                setEditData(null);
                                setIsAddUserModalVisible(true);
                            }}
                            icon={<PlusOutlined />}
                        >
                            {t('Add User')}
                        </Button>
                        {renderPagination()}
                    </Space>
                </div>
            </div>
        );
    };

    const renderText = (text: string | number) => {
        return (
            <div className={classes.cellValue}>
                {text}
            </div>
        );
    };

    const handleEditUser = async (user: User) => {
        setEditData(user);
        await fetchUserRolesedit(user.id); // Fetch user roles for edit user
        setIsAddUserModalVisible(true);
    };

    const renderActions = (row: User) => {
        return (
            <>
                <EditFilled
                    key={row.id}
                    style={{
                        color: uiTheme.primaryColor,
                        cursor: 'pointer',
                        marginRight: '10px',
                    }}
                    onClick={() => handleEditUser(row)}
                />
            </>
        );
    };

    const renderColumn = (
        text: string,
        row: User,
        column: string,
        index: number,
    ) => {
        switch (column) {
            case 'id':
                return renderText(index + 1); // (page - 1) * paginationSize + index + 1 set pagination size
            case 'Action':
                return renderActions(row);
            case 'user_code':
                return (
                    <div
                        className={classes.cellValue}
                        style={{ color: '#082E78', cursor: 'pointer' }}
                        onClick={() => {
                            setSelectedUser(row); // Set selected user
                            setIsUserDetailsModalVisible(true); // Open modal
                        }}
                    >
                        {text}
                    </div>
                );
            case 'is_active':
                return (
                    <Tag
                        color={row.is_active ? 'green' : 'red'}
                        style={{
                            borderRadius: '20px',
                        }}
                    >
                        {row.is_active ? ' Active ' : 'Inactive'}
                    </Tag>
                );
            case 'username':
                return renderText(row.username);
            case 'name':
                return renderText(row.name);
            case 'phone':
                return renderText(row.phone);
            case 'city':
                return renderText(row.city);
            case 'created_at':
                return renderText(new Date(row.created_at).toLocaleString());
            case 'updated_at':
                return renderText(new Date(row.updated_at).toLocaleString());
            default:
                return renderText(row.is_active ? 'Active' : 'Inactive');
        }
    };

    const renderColumns = () => {
        const columns: any = userColumns.map((column: TableColumn) => {
            let columnWidth = 120;

            if (column.key === 'id') {
                columnWidth = 80;
            } else if (column.key === 'user_code') {
                columnWidth = 200;
            }
            return {
                key: column.key,
                title: t(column.pretty_name),
                dataIndex: column.key,
                ellipsis: true,
                bordered: false,
                width: columnWidth,
                render: (text: string, row: any, idx: number) => renderColumn(text, row, column.key, idx),
            };
        });
        return columns;
    };

    const handleAddUserSubmit = () => {
        // Add or update user logic
        // const newUser = { ...values, status: values.is_active ? 'Active' : 'Inactive' };
        // if (editData) {
        //     const updatedUsers = users.map((user) => (user.id === editData.id ? { ...user, ...newUser } : user));
        //     setUsers(updatedUsers);
        // } else {
        //     setUsers([...users, { ...newUser, id: users.length + 1 }]);
        // }
        setIsAddUserModalVisible(false);
        fetchData();
        setEditData(null); // Reset edit data
        setDataChanged(true);
    };

    const handleAddUserCancel = () => {
        setIsAddUserModalVisible(false);
        setEditData(null); // Reset edit data
    };
    const handleFilterCancel = () => {
        setIsFilterModalVisible(false);
    };

    return (
        <div className={classes.main}>
            {renderTitle()}
            {loading && <div>Loading...</div>}
            {error && <div>{error}</div>}
            <Table
                columns={renderColumns()}
                className={classes.table}
                dataSource={users}
                pagination={false}
                rowKey={(row) => row.id.toString()}
                scroll={{ y: 650 }}
            />
            {isAddUserModalVisible
            && (
                <Modal
                    title={editData ? t('Edit User') : t('Add User')}
                    visible={isAddUserModalVisible}
                    onCancel={handleAddUserCancel}
                    style={{ left: '460px', top: '-8px' }}
                    footer={null}
                >
                    <AddUser
                        editData={editData}
                        userRoleList={userRoleList}
                        userPermissionsList={userPermissionsList}
                        onSubmit={handleAddUserSubmit}
                        onCancel={handleAddUserCancel}
                        password_policy={password_policy}
                        child_customers={child_customers}
                    />
                </Modal>
            )}
            <Modal
                title={t('Filter Users')}
                visible={isFilterModalVisible}
                onCancel={handleFilterCancel}
                style={{ left: '460px', top: '-8px', height: '100vh' }}
                bodyStyle={{ overflowY: 'auto', height: 'calc(100vh - 108px)' }}
                footer={[
                    <Button key="apply" type="primary" onClick={handleFilterApply}>
                        {t('Apply')}
                    </Button>,
                ]}
            >
                {/* Filter modal content */}
                <div>
                    <div style={{ marginBottom: 16 }}>
                        <label>{t('City')}</label>
                        <Input
                            placeholder={t('Enter City')}
                            value={selectedCity}
                            onChange={(e) => setSelectedCity(e.target.value)}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div style={{ marginBottom: 16 }}>
                        <label>{t('Phone Number')}</label>
                        <Input
                            placeholder={t('Enter 10 Digit Phone Number')}
                            value={selectedPhone}
                            onChange={(e) => setSelectedPhone(e.target.value)}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div style={{ marginBottom: 16 }}>
                        <label>{t('Status')}</label>
                        <Select
                            placeholder={t('Select Status')}
                            value={selectedStatus}
                            onChange={(value) => setSelectedStatus(value)}
                            style={{ width: '100%' }}
                        >
                            <Option value="true">{t('Active')}</Option>
                            <Option value="false">{t('Inactive')}</Option>
                        </Select>
                    </div>
                </div>
            </Modal>
            <UserDetailsModal
                user={selectedUser}
                visible={isUserDetailsModalVisible}
                onClose={() => setIsUserDetailsModalVisible(false)}
                onEdit={() => {
                    setEditData(selectedUser);
                    setIsAddUserModalVisible(true);
                }}
            />
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const {
        password_policy,
    } = state?.master?.config || {};
    const child_customers = state?.master?.child_customers || [];
    return {
        uiTheme: state.uiTheme,
        password_policy,
        child_customers,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default withStyles(userManagementStyles)(GenericHoc(hocConfig)(UserMapping));
