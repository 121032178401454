import { getEmployeeStorage, isSuperUserEmployee } from 'auth/local-storage';
import Helper from 'library/Helper';
import moment from 'moment';
import { last, isNil } from 'lodash';

export const isEmptyValue = (value: any) => isNil(value) || value === '';

export const convertToAPIBodySaveSingle = (updatedData?: any, options: Record<any, any> = {}) => {
    const {
        editData, showRiskSurchargeType,
        isCustomFieldAllowed, customFieldsList,
    } = options;
    const pickupTime = updatedData?.pickupTime?.split('-');
    const shipmentPurpose = updatedData.courierType === 'non-document'
        ? updatedData.shipmentPurpose : updatedData.shipmentPurposeDoc;
    const isCOD = updatedData.isCOD === 'yes';
    const employee = getEmployeeStorage();
    const isSuperUser = isSuperUserEmployee();
    const source = options.source || (editData ? 'CUSTOMER_PORTAL_SINGLE_UPDATE' : undefined);
    let customFields: any = {};
    if (isCustomFieldAllowed) {
        const customFieldsIds: string[] = customFieldsList?.map((item: any) => item.id);
        customFields = Object.entries(updatedData)
            .filter(([key]) => customFieldsIds?.includes(key))
            .map(([key, value]) => {
                return [key, value];
            });
    }
    if (customFields.length) {
        customFields = Object.fromEntries(customFields);
    }
    return {
        ...customFields,
        isBookedUsingV2: true,
        allPiecesWithSameDimensions: false,
        codAmount: isCOD ? (updatedData.codAmount || undefined) : undefined,
        codCollectionMode: isCOD ? (updatedData.codCollectionMode || null) : null,
        codFavorOf: isCOD ? updatedData.codFavorOf : undefined,
        // sending last value, to get final selected commodity Id in Cascader
        commodityId: Helper.isValidArray(updatedData.commodityId) ? [last(updatedData.commodityId)] : undefined,
        currency: updatedData.currency,
        consignmentCategory: updatedData.consignmentCategory,
        consignmentType: updatedData.consignmentType,
        courierType: updatedData.courierType,
        customerReferenceNumber: updatedData.customerReferenceNumber,
        copAmount: updatedData.cashonpickup,
        isRetailTransaction: updatedData.retailTransactionType === 'retailTransactionTrue' || false,
        typeOfDelivery: updatedData.typeOfDelivery,
        description: updatedData.description,
        dimensions: {
            length: isEmptyValue(updatedData.length)
                ? null
                : Helper.converttoCentimeter(updatedData.length, updatedData.unit),
            width: isEmptyValue(updatedData.width)
                ? null
                : Helper.converttoCentimeter(updatedData.width, updatedData.unit),
            height: isEmptyValue(updatedData.height)
                ? null
                : Helper.converttoCentimeter(updatedData.height, updatedData.unit),
        },
        dstAddress: updatedData.dstAddress,
        ewayBill: updatedData.consignmentCategory === 'international'
            ? updatedData.ewayBillNumber : updatedData.ewayBill,
        kiranaHubCode: updatedData.kiranaHubCode,
        isFragile: false,
        isInternational: updatedData.consignmentCategory === 'international',
        isRiskSurchargeApplicable: updatedData.isRiskSurchargeApplicable,
        numberOfPieces: isEmptyValue(updatedData.numberOfPieces) ? null : Number(updatedData.numberOfPieces),
        // piecesDetail: apiBodyPieceDetails(updatedData.pieces, updatedData.courierType === 'document'),
        referenceNumber: updatedData.referenceNumber,
        retAddress: updatedData.retAddress,
        serviceType: updatedData.serviceType,
        srcAddress: updatedData.srcAddress,
        piecesDetail: updatedData?.piecesDetail,
        weight: isEmptyValue(updatedData.weight)
            ? null
            : Helper.converttoKilogram(Number(updatedData.weight), updatedData.weightUnit),
        declaredPrice: updatedData.declaredValue,
        alternateDelAddress1: updatedData.alternateDelAddress1,
        alternateDelAddress2: updatedData.alternateDelAddress2,
        redirectionAddress: updatedData.redirectionAddress,
        childClient: updatedData.childClient ? {
            code: updatedData.childClient.split('+')[0],
            id: updatedData.childClient.split('+')[1],
        } : null,
        custSellerCode: updatedData.custSellerCode,
        shipmentPurpose,
        deliveryInstructions: updatedData.deliveryInstructions,
        eoriNumber: updatedData.eoriNumber,
        ioriNumber: updatedData.ioriNumber,
        freightCost: updatedData.freightCost,
        freightCostCurrency: updatedData.freightCostCurrency,
        againstBondLut: updatedData.againstBondLut,
        ecomShipment: updatedData.ecomShipment ? updatedData.ecomShipment === 'true' : undefined,
        meis: updatedData.meis ? updatedData.meis === 'true' : undefined,
        isBattery: updatedData.isBattery ? updatedData.isBattery === 'true' : undefined,
        exportInvoiceDate: updatedData?.invoiceDate?.format('YYYY-MM-DD'),
        fobValueCurrency: updatedData.fobValueCurrency,
        fobValue: updatedData.fobValue,
        totalGSTPaidCurrency: updatedData.totalGSTPaidCurrency,
        totalGSTPaidAmount: updatedData.totalGSTPaidAmount,
        consignorCompanyName: updatedData.consignorCompanyName,
        consignorTaxId: updatedData.consignorTaxId,
        consignorIECNumber: updatedData.consignorIECNumber,
        consignorGSTINNumber: updatedData.consignorGSTINNumber,
        consignorPanId: updatedData.consignorPanId,
        consignorKycDocType: updatedData.consignorKycDocType,
        consignorKycDocNumber: updatedData.consignorKycDocNumber,
        consignorKycFrontImage: updatedData.consignorKycFrontImage,
        consignorKycBackImage: updatedData.consignorKycBackImage,
        senderBankAccountNumber: updatedData.senderBankAccountNumber,
        senderBankADCode: updatedData.senderBankADCode,
        senderBankIFSCCode: updatedData.senderBankIFSCCode,
        nefiFlag: updatedData.nefiFlag ? updatedData.nefiFlag === 'true' : undefined,
        cpcCode: updatedData.cpcCode,
        cessValue: updatedData.cessValue,
        ewayBillNumber: updatedData.ewayBillNumber,
        invoiceType: updatedData.invoiceType,
        invoiceDate: updatedData?.invoiceDate?.format('YYYY-MM-DD'),
        invoiceNumber: updatedData.invoiceNumber,
        billAddress: updatedData.billToDetails,
        consigneeCompanyName: updatedData.consigneeCompanyName,
        incoTerms: updatedData.incoTerms,
        deliveryTerms: updatedData.deliveryTerms,
        originType: updatedData.originType,
        destinationType: updatedData.destinationType,
        // sending the dimenstion & weight data after converting api body
        dimensionUnit: 'cm',
        weightUnit: 'kg',
        receiverVATNumber: updatedData.receiverVATNumber,
        pickupDate: moment(updatedData.pickupSchedule).format('YYYY-MM-DD'),
        pickupTimeSlotStart: pickupTime ? pickupTime[0]?.trim() : undefined,
        pickupTimeSlotEnd: pickupTime ? pickupTime[1]?.trim() : undefined,
        declaredCurrency: updatedData.declaredCurrency,
        unlistedCommodityName: updatedData?.unlistedCommodityName,
        ewbNumber: updatedData.ewbNumber,
        ewbDate: moment(updatedData.ewbDate).format('YYYY-MM-DD'),
        riskSurchargeType: showRiskSurchargeType
            ? updatedData.riskSurchargeType : null,
        consignmentFlowType: 'Standard',
        source,
        createdBy: isSuperUser ? `${employee?.employeeCode || ''} (Employee)`
            : `${updatedData.childClient?.split('+')[0] || ''} (Customer)`,
        invoiceAmount: updatedData.invoiceAmount,
        customsAmount: updatedData.customsAmount,
        movementReferenceNumber: updatedData.movementReferenceNumber,
        purpose: updatedData.purpose,
        isCustomsDeclarable: updatedData.isCustomsDeclarable === 'true',
        consigneeCode: updatedData.consigneeCode,
        dangerousGoodAccessible: updatedData.dangerousGoodAccessible === 'true',
        isCsbv: updatedData.isCsbv,
        manufacturingCountry: updatedData.manufacturingCountry,
        taxPercentage: updatedData.taxPercentage,
        totalTax: updatedData.totalTax,
        dangerousGoodCategory: updatedData.dangerousGoodCategory,
        dangerousGoodCategoryPackingInstruction: updatedData.dangerousGoodCategoryPackingInstruction,
        dangerousGoodCategoryType: updatedData.dangerousGoodCategoryType,
        dangerousGoodClass: updatedData.dangerousGoodClass,
        dangerousGoodDivision: updatedData.dangerousGoodDivision,
        dangerousGoodName: updatedData.dangerousGoodName,
        dangerousGoodPackingGroup: updatedData.dangerousGoodPackingGroup,
        dangerousGoodUnNumber: updatedData.dangerousGoodUnNumber,
        isDangerousGood: updatedData.isDangerousGood === 'true',
        isStackable: updatedData.isStackable === 'yes',
    };
};

export const internationalFields = [
    'custSellerCode',
    'shipmentPurposeDoc',
    'deliveryTerms',
    'deliveryInstructions',
    'eoriNumber',
    'againstBondLut',
    'ecomShipment',
    'meis',
    'isBattery',
    'receiverVATNumber',
    'consigneeCompanyName',
    'consignorCompanyName',
    'originType',
    'consignorIECNumber',
    'consignorGSTINNumber',
    'consignorPanId',
    'senderBankAccountNumber',
    'senderBankADCode',
    'senderBankIFSCCode',
    'nefiFlag',
    'cpcCode',
    'consignorKycDocType',
    'consignorKycDocNumber',
    'consignorKycFrontImage',
    'consignorKycBackImage',
    'ewayBillNumber',
    'invoiceType',
    'ewbNumber',
    'type',
    'ewayBillDownloadDeclaration',
    'transaction',
    'reasonTransport',
    'transMode',
    'transDistance',
    'documentType',
    'sendername',
    'senderaddressLine1',
    'senderaddressLine2',
    'senderpincode',
    'sendercityName',
    'senderstateName',
    'sendercountryName',
    'senderphone',
    'recievername',
    'recieveraddressLine1',
    'recieveraddressLine2',
    'recieverpincode',
    'recievercityName',
    'recieverstateName',
    'recievercountryName',
    'recieverphone',
    'senderGstin',
    'recieverGstin',
    'prodName',
    'hsnCode',
    'ewayBillDescription',
    'quantity',
    'unit',
    'totalValue',
    'cgstValue',
    'igstValue',
    'sgstValue',
    'cessValue',
    'freightCost',
    'fobValue',
    'totalGSTPaidAmount',
    'boxDetails',
    'importConsignorDataControl',
    'destinationType',
];

export const domesticFields = [
    'isCOD',
    'codAmount',
    'codCollectionMode',
    'codFavorOf',
];

export const nonDocumentFields = [
    'non_document',
    'boxDetails',
];
