import { Form, TimePicker } from 'antd';
import moment from 'moment';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import React, { useEffect, useState } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { StylesProps } from 'theme/jss-types';
import { useTranslation } from 'react-i18next';

const styles = () => ({
    timeSlotDiv: {
        width: '150px',
        marginRight: '8px',
        marginBottom: '10px',
        marginTop: '17px',
    },
    timeSlotParent: {
        marginBottom: '0px',
    },
    labelDiv: {
        color: '#333333',
        fontSize: '12px',
        marginBottom: '4px',
        marginTop: '33px',
    },
    '@global': {
        '.ant-form-item-label > label::after': {
            content: '":"',
            position: 'relative',
            top: '-1px',
            margin: '27px 8px 0 2px',
            color: '#333',
            fontSize: '12px',
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>> {
    form: any;
    format: any;
    label: any;
    currentAction: any;
    timeSlotList: any;
    timeSlotKeys: any;
    setTimeSlotKeys: any;
    timeSlotValues: any;
    setTimeSlotValues: any;
}


const TimeRangePickerList = (props: IProps) => {
    const { t } = useTranslation();
    const {
        form,
        format = 'HH:mm',
        label,
        currentAction = 'edit',
        timeSlotList,
        timeSlotKeys,
        setTimeSlotKeys,
        timeSlotValues,
        setTimeSlotValues,
        classes,
    } = props;

    const [id, setId] = useState(0);

    const getInitialValue = () => {
        if (currentAction === 'edit') {
            const keys: number[] = [];
            const values: any = {};
            let index = 0;

            timeSlotList?.forEach((timeSlot: string[]) => {
                if (timeSlot?.length === 2) {
                    keys.push(index);
                    values[index] = [
                        moment(timeSlot[0], format),
                        moment(timeSlot[1], format),
                    ];
                    index += 1;
                }
            });

            return {
                keys: keys.length ? keys : [0],
                values: keys.length ? values : {},
            };
        }
        return {
            keys: [0],
            values: {},
        };
    };

    useEffect(() => {
        const initialValues = getInitialValue();
        setTimeSlotKeys(initialValues.keys);
        setTimeSlotValues(initialValues.values);
        setId(initialValues.keys[initialValues.keys.length - 1]);
    }, [currentAction]);

    const remove = (key: number) => {
        const updatedTimeSlotKeys = timeSlotKeys?.filter((k: any) => k !== key);
        const updatedTimeSlotValues = Object.keys(timeSlotValues)
            ?.filter((k) => parseInt(k, 10) !== key)
            ?.reduce((acc, k) => {
                return { ...acc, [k]: timeSlotValues[k] };
            }, {});

        setTimeSlotKeys(updatedTimeSlotKeys);
        setTimeSlotValues(updatedTimeSlotValues);

        form.setFieldsValue({
            timeSlotKeys: updatedTimeSlotKeys,
            timeSlotValues: updatedTimeSlotValues,
        });
    };

    const add = () => {
        const nextId = id + 1;
        const nextKeys = [...timeSlotKeys, nextId];
        setId(nextId);
        setTimeSlotKeys(nextKeys);
    };

    const handleTimeChange = (key: number, timeRange: any) => {
        const updatedValues = { ...timeSlotValues, [key]: timeRange };
        setTimeSlotValues(updatedValues);

        form.setFieldsValue({
            timeSlotValues: updatedValues,
        });
    };

    return (
        <div style={{ marginBottom: '10px' }}>
            {timeSlotKeys?.map((key: number, index: number) => (
                <Form.Item
                    key={key}
                    style={{ marginBottom: '0px' }}
                    label={
                        index === 0 ? (
                            <span className={classes.labelDiv}>
                                {t(label)}
                            </span>
                        ) : (
                            ''
                        )
                    }
                >
                    <div className={classes.timeSlotParent} style={{ display: 'flex', alignItems: 'center' }}>
                        <TimePicker.RangePicker
                            format={format}
                            className={classes.timeSlotDiv}
                            style={{
                                marginLeft: index !== 0 ? '65px' : undefined,
                                marginTop: index !== 0 ? '8px' : undefined,
                            }}
                            value={timeSlotValues[key]}
                            onChange={(value) => handleTimeChange(key, value)}
                        />
                        {timeSlotKeys.length > 1 && (
                            <MinusCircleOutlined onClick={() => remove(key)} style={{ fontSize: '16px' }} />
                        )}
                    </div>
                </Form.Item>
            ))}
            {timeSlotKeys.length < 3 && (
                <Form.Item>
                    <div onClick={add} style={{ color: '#065492', cursor: 'pointer', marginLeft: '74px' }}>
                        <PlusOutlined />
                        {t('add_more_text')}
                    </div>
                </Form.Item>
            )}
        </div>
    );
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(TimeRangePickerList);
