export const addressBookColumns: Record<string, string> = {
    serialNumber: 'S.No',
    addressCode: 'Address Code',
    companyName: 'Company Name',
    name: 'Name',
    pincode: 'Pincode',
    phone: 'Mobile No.',
    alternatePhone: 'Alternate Phone',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    cityName: 'City',
    stateName: 'State',
    countryName: 'Country',
    latitude: 'Latitude',
    longitude: 'Longitude',
    email: 'Email',
    timeSlotList: 'Time Slot',
    actions: 'Actions',
};
