import * as React from 'react';
import Loader from '../components/common/Loader';
import {
    Switch, Route, Redirect, useLocation,
} from 'react-router-dom';
import DownloadPage from '../components/download/downloads';
import WalletPage from '../components/wallet/wallet';
import Pickups from '../components/pickup/pickup-view/pickup';
import ConsignmentPage from '../components/consignments/consignments';
import AdvanceShippingNotePage from '../components/advanceShippingNote/advanceShippingNote';
import VirtualInventory from '../components/virtual-inventory/virtual-inventory';
import AddressBook from '../components/address-book/address-book';
import AnalyticsPage from '../components/analytics/analytics';
import APIPlaygroundPage from '../components/api-playground/api-playground';
import USER_MANAGEMENT from '../components/user-management/user-management';
import {
    getConsignmentDetailsRoute,
    getConsignmentsRoute,
    getDefaultLandingRoute,
    getPluginOrdersRoute,
    getDownloadsRoute,
    getExcelUploadRoute,
    getHeaderMappingRoute,
    getPieceHeaderMappingRoute,
    getPickupRoute,
    getPrintHistoryRoute,
    getProductMappingRoute,
    getSettingsChannelRoute,
    getSettingsAccountRoute,
    getVirtualInventoryRoute,
    getWalletLedgerRoute,
    getBlockedAmountRoute,
    getAsnExcelUploadRoute,
    getAdvanceShippingNoteRoute,
    getAddressBookRoute,
    getCustomerActivationRoute,
    getAnalyticsRoute,
    getRtoDashboardRoute,
    getRemittanceReportsRoute,
    getForgotPasswordRoute,
    getInvoiceReportsRoute,
    getAdditionalServicesRoute,
    getAPIPlaygroundRoute,
    getBlockedLedgerRoute,
    getSchedulePickupRoute,
    getUserManagement,
    getPurchaseOrderRoute,
} from './routing-helper';
import { loadInitialData } from '../actions/load-initial-data';
import { bindActionCreators } from 'redux';
import { ReduxStore } from '../reducers/redux.types';
import { HocOptions } from '../components/common/generic-hoc.types';
import { Buckets } from '../library/Types';
import { Master } from '../types/master-data-types';
import GenericHoc from '../components/common/generic-hoc';
import SettingsIframe from '../components/settings-product/settings';
import RTODashboard from 'components/rto-dashboard/rto-dashboard';
import ReportsIframe from '../components/remittance-report/reports';
// import RemittanceReport from 'components/remittance-report/remittance-report';
import { setFilters, applyFilters } from '../actions/generic-action';
import CreatePassword from 'components/sign-in/create-password';
import ResetPassword from 'components/sign-in/reset-password';
import AdditionalServices from 'components/additional-services/additional-services';
import { trackPageView } from 'library/google-analytics-helper';
import PurchaseOrders from 'components/purchase-orders/purchase-orders';

// import DefaultUrl from './default-page';

interface IProps {
    loadinitial: () => void;
    master: Master;
    fetchingMaster: boolean;
    asnFilters?: Record<string, any>;
    setfilters: (bucketId: Buckets.ASN, filters: any) => void;
    applyfilters: (bucketId: Buckets.ASN) => void;
}

const AutheticatedRoutes = (props: IProps) => {
    const {
        master,
        loadinitial,
        fetchingMaster,
        asnFilters,
        setfilters,
        applyfilters,
    } = props;

    const location = useLocation();

    const handleBucketChangeAsn = () => {
        const isParentCustomer = JSON.parse(window.localStorage.getItem('parentId') as string);
        const newFilters = {
            ...asnFilters,
            bucketSelected: isParentCustomer ? 'customer_portal_asn_assigned' : 'customer_portal_asn_uploaded',
        };
        setfilters(Buckets.ASN, newFilters);
        applyfilters(Buckets.ASN);
    };

    React.useEffect(() => {
        if (!master.masterDataLoaded) {
            loadinitial();
        }
        handleBucketChangeAsn();
    }, []);

    React.useEffect(() => {
        trackPageView(location.pathname);
    }, [location.pathname]);

    if (fetchingMaster || !master.masterDataLoaded) {
        return (
            <Loader
                zIndex={100}
            />
        );
    }

    return (
        <React.Suspense fallback={<Loader zIndex={5} />}>
            <Switch>
                <Route exact path={getConsignmentsRoute()} render={() => <ConsignmentPage />} />
                <Route path={getConsignmentDetailsRoute()} render={() => <ConsignmentPage />} />
                <Route exact path={getPluginOrdersRoute()} render={() => <ConsignmentPage />} />
                <Route exact path={getExcelUploadRoute()} render={() => <ConsignmentPage />} />
                <Route exact path={getPrintHistoryRoute()} render={() => <ConsignmentPage />} />
                <Route exact path={getAdvanceShippingNoteRoute()} render={() => <AdvanceShippingNotePage />} />
                <Route exact path={getAsnExcelUploadRoute()} render={() => <AdvanceShippingNotePage />} />
                <Route path={getVirtualInventoryRoute()} render={() => <VirtualInventory />} />
                <Route exact path={getPickupRoute()} render={() => <Pickups />} />
                <Route exact path={getAddressBookRoute()} render={() => <AddressBook />} />
                <Route exact path={getRtoDashboardRoute()} render={() => <RTODashboard />} />
                <Route exact path={getDownloadsRoute()} render={() => <DownloadPage />} />
                <Route exact path={getWalletLedgerRoute()} render={() => <WalletPage />} />
                <Route exact path={getBlockedAmountRoute()} render={() => <WalletPage />} />
                <Route exact path={getBlockedLedgerRoute()} render={() => <WalletPage />} />
                <Route exact path={getRemittanceReportsRoute()} render={() => <ReportsIframe />} />
                <Route exact path={getInvoiceReportsRoute()} render={() => <ReportsIframe />} />
                <Route exact path={getHeaderMappingRoute()} render={() => <SettingsIframe />} />
                <Route exact path={getSchedulePickupRoute()} render={() => <SettingsIframe />} />
                <Route exact path={getPieceHeaderMappingRoute()} render={() => <SettingsIframe />} />
                <Route exact path={getProductMappingRoute()} render={() => <SettingsIframe />} />
                <Route exact path={getSettingsChannelRoute()} render={() => <SettingsIframe />} />
                <Route exact path={getSettingsAccountRoute()} render={() => <SettingsIframe />} />
                <Route exact path={getCustomerActivationRoute()} render={() => <CreatePassword />} />
                <Route exact path={getForgotPasswordRoute()} render={() => <ResetPassword />} />
                <Route path={getAnalyticsRoute()} render={() => <AnalyticsPage />} />
                <Route exact path={getAdditionalServicesRoute()} render={() => <AdditionalServices />} />
                <Route path={getAPIPlaygroundRoute()} render={() => <APIPlaygroundPage />} />
                <Route path={getUserManagement()} render={() => <USER_MANAGEMENT />} />
                <Route exact path={getPurchaseOrderRoute()} render={() => <PurchaseOrders />} />
                <Route path="/" render={() => <Redirect to={getDefaultLandingRoute()} />} />
            </Switch>
        </React.Suspense>
    );
};

const mapStateToProps = (store: ReduxStore) => ({
    master: store.master,
    fetchingMaster: store.master.fetchingMaster,
    asnFilters: store.generic.ASN.filters,
});

const mapDispatchToProps = (dispatch: any) => (bindActionCreators({
    loadinitial: loadInitialData,
    setfilters: setFilters,
    applyfilters: applyFilters,
}, dispatch));

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
};
const Authenticated = GenericHoc(hocConfig)(AutheticatedRoutes);
export default Authenticated;
