import * as React from 'react';
import withStyles from 'react-jss';
import Loader from '../../common/Loader';
import CreateAddress from '../../address/create-address';
import CreateAddress2 from '../../address/create-address2';
import AddressIcon from '../single-consignment-icons/address';
import { StylesProps, ThemeType } from '../../../theme/jss-types';
import { formFields } from '../create-modal.constants';
import { stepTwoStyles } from '../single-consignment.styles';
import { PickupAddress } from '../../pickup/create-pickup/pickup.types';
import { useDebounce } from '../../../hooks/use-debounce';
import {
    deleteAddress,
    fetchPickupAddressMultiple,
    getDefaultAddress,
    setDefaultAddressData,
    editAddress as editBusinessAddress,
} from '../../../network/pickup.api';
import {
    Button,
    Checkbox,
    Form,
    FormInstance,
    Input,
    message,
    Popconfirm,
    Radio,
} from 'antd';
import Helper from '../../../library/Helper';
import {
    BookOutlined,
    CheckCircleFilled, DeleteOutlined, EditOutlined, ExclamationCircleFilled,
} from '@ant-design/icons';
import { isEqual, uniqWith } from 'lodash';
import { Master } from 'types/master-data-types';
import { formRules } from 'library/constants';
import { useTranslation } from 'react-i18next';
import { ReduxStore } from 'reducers/redux.types';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import Paginator from 'components/common/paginator';
import { NextOrPrev, Pagination } from 'library/Types';

const {
    AddressTypes,
} = formFields;

const { useState } = React;
interface StepTwoProps extends StylesProps<ReturnType<typeof stepTwoStyles>> {
    page: 'Consignee' | 'Consignor';
    formData: any;
    form: FormInstance;
    addressKey: string;
    setFormData: any;
    phoneRegex: any;
    allowDefaultSourceAddress: boolean;
    fieldsToShowInternational: Record<any, boolean>;
    config: Master;
    pageTitle: string;
    countryWisePhoneRegex: any[];
    isRTL: any,
    uiTheme: ThemeType;
    master: Master;
    autofillAddressFieldsBasedOnAddressHierarchy: boolean;
    setCreatingAddress: any;
}

const StepTwo = (props: StepTwoProps) => {
    const {
        form,
        page,
        classes,
        formData,
        addressKey,
        setFormData,
        phoneRegex,
        countryWisePhoneRegex,
        allowDefaultSourceAddress,
        fieldsToShowInternational,
        config,
        pageTitle,
        isRTL,
        uiTheme,
        master,
        autofillAddressFieldsBasedOnAddressHierarchy,
        setCreatingAddress,
    } = props;

    const { t, i18n } = useTranslation();
    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    const [billToSame, setBillToSame] = useState<boolean>(false);
    const [updateAddress, setUpdateAddress] = useState<boolean>(false);
    const showAlternateAddress = config?.parts_to_show?.allow_multiple_alternate_address_in_fm_api;
    const defaultMultipleLimit = config?.config?.customer_portal_config?.default_addresses_limit;
    const isDestinationTypeRequired = config?.config?.customer_portal_config?.mandatory_shipment_commercial?.destinationType
        && formData.consignmentCategory === 'international' && formData.shipmentPurpose === 'COMMERCIAL'
        && formData.courierType === 'non-document';
    const showAddressCode = config?.config?.customer_portal_config?.allow_address_code_in_addresses;
    const [defaultAddress, setDefaultAddress] = useState<Record<any, any>>({});
    const [isDefaultLoaded, setIsDefaultLoaded] = useState(false);
    const [pagination, setPagination] = React.useState<Pagination>({
        nextOrPrev: 'first',
        currentPageNumber: 1,
        resultPerPage: 20,
        isNextPresent: false,
        lastUpdatedAt: null,
    });
    const [searchString, setSearchString] = useState<Record<string, string>>({
        srcAddress: '',
        dstAddress: '',
        alternateDelAddress1: '',
        alternateDelAddress2: '',
        redirectionAddress: '',
        billToDetails: '',
    });
    const [addressList, setAddressList] = useState<Record<string, PickupAddress[]>>({
        srcAddress: [],
        dstAddress: [],
        alternateDelAddress1: [],
        alternateDelAddress2: [],
        redirectionAddress: [],
        billToDetails: [],
    });
    const [addressListVisited, setAddressListVisited] = useState<Record<string, boolean>>({
        srcAddress: true,
        dstAddress: true,
        alternateDelAddress1: false,
        alternateDelAddress2: false,
        redirectionAddress: false,
        billToDetails: false,
    });
    const [addressTypeVisited, setAddressTypeVisited] = useState<Record<string, boolean>>({
        srcAddress: false,
        dstAddress: true,
        alternateDelAddress1: false,
        alternateDelAddress2: false,
        redirectionAddress: false,
        billToDetails: false,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedAddress, setSelected] = React.useState<Record<string, PickupAddress | null>>({
        srcAddress: null,
        dstAddress: null,
        alternateDelAddress1: null,
        alternateDelAddress2: null,
        redirectionAddress: null,
        billToDetails: null,
    });
    const [addressType, setAddressType] = React.useState<Record<string, string>>({
        srcAddress: 'saved',
        dstAddress: 'saved',
        alternateDelAddress1: 'saved',
        alternateDelAddress2: 'saved',
        redirectionAddress: 'saved',
        billToDetails: 'saved',
    });
    const [addressTypeRadio, setAddressTypeRadio] = React.useState<string>(addressKey);
    const [editAddress, setEditAddress] = React.useState<PickupAddress | undefined>();
    const [pluginAddressType, setPluginAddressType] = React.useState<string>('');
    const [toggle, setToggle] = React.useState<boolean>(true);

    const loadDefaultAddress = async () => {
        if (!allowDefaultSourceAddress) return;
        setLoading(true);
        const response = await getDefaultAddress();
        if (!response.isSuccess) {
            message.error(response.errorMessage);
        } else {
            setDefaultAddress(response.data);
        }
        setLoading(false);
    };

    const loadAddress = async (key: string) => {
        if (addressType[key] !== 'saved') {
            return;
        }
        let defaultAdd : Record<any, any> = {};
        if (allowDefaultSourceAddress && !isDefaultLoaded && key === 'srcAddress') {
            const response = await getDefaultAddress();
            if (response.isSuccess) {
                defaultAdd = response?.data || {};
                setDefaultAddress(defaultAdd);
                setIsDefaultLoaded(true);
            }
        } else {
            defaultAdd = defaultAddress;
        }
        let showDefaultBy = '';
        if (defaultMultipleLimit) {
            if (key === 'srcAddress') {
                showDefaultBy = 'src';
            } else {
                showDefaultBy = 'dst';
            }
        }
        setLoading(true);
        const response = await fetchPickupAddressMultiple({
            searchString: searchString[key] || '',
            parentCustomerId: master?.properties?.parent_address_book ? master?.Customer?.parent_id : undefined,
            pageNumber: pagination?.currentPageNumber,
            limit: 20,
            showDefaultBy,
        });
        if (!response.isSuccess) {
            message.error(response.errorMessage);
        }
        let data = response?.data?.page_data || [];
        // eslint-disable-next-line no-underscore-dangle
        if (key === 'srcAddress' && allowDefaultSourceAddress && defaultAdd?._id
            && searchString[key].length < 1 && !defaultMultipleLimit) {
            let add: PickupAddress | undefined;
            data = await data.filter((item: PickupAddress) => {
                // eslint-disable-next-line no-underscore-dangle
                if (item.id === defaultAdd._id) {
                    add = item;
                    return false;
                }
                return true;
            });
            if (add) await data.unshift(add);
        }
        if (formData?.previousSelectedAddress && formData?.previousSelectedAddress[key]) {
            data.unshift(formData?.previousSelectedAddress[key]);
        }
        const isNextPresent = response?.data?.is_next_present || false;
        setPagination({
            ...pagination,
            isNextPresent,
        });
        setAddressList((oldAddressList) => {
            return {
                ...oldAddressList,
                [key]: uniqWith(data, isEqual),
            };
        });
        setLoading(false);
    };


    const saveDefaultAddress = async (address: PickupAddress, isMultipleDefault: boolean = false) => {
        setLoading(true);
        if (isMultipleDefault) {
            const response = await editBusinessAddress(address);
            if (!response.isSuccess) {
                message.error(response.errorMessage);
            } else {
                loadAddress(addressTypeRadio);
            }
        } else {
            const id = address.id;
            const response = await setDefaultAddressData({
                businessAddressId: id,
            });
            if (!response.isSuccess) {
                message.error(response.errorMessage);
            } else {
                loadDefaultAddress();
                setLoading(true);
                const key = 'srcAddress';
                if (addressTypeRadio === key) {
                    let data = addressList[key] || [];
                    // eslint-disable-next-line no-underscore-dangle
                    if (allowDefaultSourceAddress && id && searchString[key].length < 1) {
                        let add: PickupAddress | undefined;
                        data = data.filter((item: PickupAddress) => {
                            // eslint-disable-next-line no-underscore-dangle
                            if (item.id === id) {
                                add = item;
                                return false;
                            }
                            return true;
                        });
                        if (add) data.unshift(add);
                        setAddressList((oldAddressList) => {
                            return {
                                ...oldAddressList,
                                [key]: data,
                            };
                        });
                    }
                }
            }
        }
        setLoading(false);
    };

    const prefillData = () => {
        form.setFieldsValue({
            srcAddress: formData.srcAddress,
            dstAddress: formData.dstAddress,
            alternateDelAddress1: formData.alternateDelAddress1,
            alternateDelAddress2: formData.alternateDelAddress2,
            redirectionAddress: formData.redirectionAddress,
            billToDetails: formData.billToDetails,
            consigneeCompanyName: formData.consigneeCompanyName,
            destinationType: formData.destinationType,
            receiverVATNumber: formData.receiverVATNumber,
        });
        setSelected({
            srcAddress: formData.srcAddress,
            dstAddress: formData.dstAddress,
            alternateDelAddress1: formData.alternateDelAddress1,
            alternateDelAddress2: formData.alternateDelAddress2,
            redirectionAddress: formData.redirectionAddress,
            billToDetails: formData.billToDetails,
        });
        setBillToSame(formData.billToSame || false);
    };

    React.useEffect(() => {
        if (!updateAddress) return;
        setUpdateAddress(false);
        if (selectedAddress[addressTypeRadio]) {
            let selectedAdd = null;
            addressList[addressTypeRadio].forEach((address) => {
                if (address.id === selectedAddress[addressTypeRadio]?.id) {
                    selectedAdd = address;
                }
            });
            setSelected({
                ...selectedAddress,
                [addressTypeRadio]: selectedAdd,
            });
            form.setFieldsValue({
                [addressTypeRadio]: selectedAdd,
            });
            setFormData({
                ...formData,
                [addressTypeRadio]: selectedAdd,
            });
        }
    }, [addressList]);

    const debounceTimeSrc = useDebounce(searchString.srcAddress);
    React.useEffect(() => {
        if (addressTypeRadio === 'srcAddress') {
            loadAddress('srcAddress');
        }
    }, [debounceTimeSrc, addressType.srcAddress, toggle, addressTypeVisited.srcAddress]);

    const debounceTimeDst = useDebounce(searchString.dstAddress);
    React.useEffect(() => {
        if (addressTypeRadio === 'dstAddress') {
            loadAddress('dstAddress');
        }
    }, [debounceTimeDst, addressType.dstAddress, toggle, addressTypeVisited.dstAddress]);

    const debounceTimeAlt1 = useDebounce(searchString.alternateDelAddress1);
    React.useEffect(() => {
        if (addressTypeRadio === 'alternateDelAddress1') {
            loadAddress('alternateDelAddress1');
        }
    }, [debounceTimeAlt1, addressType.alternateDelAddress1, toggle, addressTypeVisited.alternateDelAddress1]);


    const debounceTimeAlt2 = useDebounce(searchString.alternateDelAddress2);
    React.useEffect(() => {
        if (addressTypeRadio === 'alternateDelAddress2') {
            loadAddress('alternateDelAddress2');
        }
    }, [debounceTimeAlt2, addressType.alternateDelAddress2, toggle, addressTypeVisited.alternateDelAddress2]);

    const debounceTimeRedirection = useDebounce(searchString.redirectionAddress);
    React.useEffect(() => {
        if (addressTypeRadio === 'redirectionAddress') {
            loadAddress('redirectionAddress');
        }
    }, [debounceTimeRedirection, addressType.redirectionAddress, toggle, addressTypeVisited.redirectionAddress]);

    const debounceTimebilling = useDebounce(searchString.billToDetails);
    React.useEffect(() => {
        if (addressTypeRadio === 'billToDetails') {
            loadAddress('billToDetails');
        }
    }, [debounceTimebilling, addressType.billToDetails, toggle, addressTypeVisited.billToDetails]);


    React.useEffect(() => {
        prefillData();
    }, []);

    React.useEffect(() => {
        if (billToSame) {
            setFormData({
                ...formData,
                billToDetails: formData.dstAddress,
            });
            form.setFieldsValue({
                billToDetails: formData.dstAddress,
            });
            setSelected({
                ...selectedAddress,
                billToDetails: formData.dstAddress,
            });
        }
    }, [formData.dstAddress]);

    const renderRowDescription = (FieldIcon: React.ElementType, label: string) => {
        return (
            <div className={classes.boxTitle}>
                <div className={classes.boxIcon}>
                    <FieldIcon className={classes.boxIcon} />
                </div>
                <span>{label}</span>
            </div>
        );
    };

    const isCurrentAddressSelected = (key: string, item: PickupAddress) => {
        return Boolean(selectedAddress && selectedAddress[key] && item.id === selectedAddress[key]?.id);
    };

    const handleDelete = async (e: any, id: string) => {
        Helper.stops(e);
        const result = await deleteAddress({ id });
        if (result.isSuccess) {
            message.success('Address Deleted Successfully');
            setToggle(!toggle);
        } else {
            message.error(result.errorMessage);
        }
    };

    const renderDelete = (item: PickupAddress, isEnabled?: boolean) => {
        return (isEnabled ? (
            <Popconfirm
                placement="topLeft"
                title={t('delete_address_text')}
                onConfirm={(e) => handleDelete(e, item.id)}
                okText={t('ok_text')}
                cancelText={t('cancel')}
                onCancel={(e) => Helper.stops(e as any)}
            >
                <DeleteOutlined
                    onClick={(e) => {
                        Helper.stops(e as any);
                    }}
                    style={{ color: 'red' }}
                />
            </Popconfirm>
        ) : (
            <DeleteOutlined
                style={{
                    color: 'lightgray',
                }}
            />
        ));
    };

    const renderEdit = (item: PickupAddress, key: string, isEnabled?: boolean) => {
        const isAddressSelected = isCurrentAddressSelected(key, item);
        return (
            <EditOutlined
                onClick={(e) => {
                    if (isEnabled) {
                        Helper.stops(e as any);
                        if (isAddressSelected) {
                            return;
                        }
                        setAddressType({
                            ...addressType,
                            [key]: 'createnew',
                        });
                        form.setFieldsValue({
                            [`addressOption${key}`]: 'createnew',
                        });
                        setUpdateAddress(true);
                        setPluginAddressType(key);
                        setEditAddress(item);
                        setCreatingAddress(true);
                    }
                }}
                style={{ color: isEnabled && !isAddressSelected ? uiTheme.primaryColor : 'lightgray', marginRight: '0 8px' }}
            />
        );
    };

    const renderParentAddress = (isParent: boolean) => {
        return (
            <span
                style={{
                    marginRight: '15px',
                    fontSize: 'small',
                    padding: '2px 8px',
                    borderRadius: '12px',
                    border: isParent ? '1px solid' : 'none',
                }}
            >
                {isParent ? 'Parent' : ''}
            </span>
        );
    };

    const renderMarkAsDefaultMultiple = (item: PickupAddress) => {
        const isMultipleDefault = addressTypeRadio === 'srcAddress' ? item?.isDefaultSrc : item?.isDefaultDst;
        return (
            <>
                { isMultipleDefault ? (
                    <Button
                        style={{ cursor: 'default' }}
                        shape="round"
                    >
                        Default
                    </Button>
                ) : null}
                { isMultipleDefault ? (
                    <Popconfirm
                        placement="topLeft"
                        title="Are you sure you want to remove this default Address"
                        onConfirm={(e) => {
                            Helper.stops(e as any);
                            saveDefaultAddress({
                                ...item,
                                isDefaultSrc: addressTypeRadio === 'srcAddress' ? false : item?.isDefaultSrc,
                                isDefaultDst: addressTypeRadio !== 'srcAddress' ? false : item?.isDefaultDst,
                            }, true);
                        }}
                        onCancel={(e) => Helper.stops(e as any)}
                    >
                        <BookOutlined
                            style={{ color: 'red' }}
                            onClick={(e) => { Helper.stops(e as any); }}
                        />
                    </Popconfirm>
                ) : (
                    <BookOutlined
                        onClick={(e) => {
                            Helper.stops(e as any);
                            saveDefaultAddress({
                                ...item,
                                isDefaultSrc: addressTypeRadio === 'srcAddress' ? true : item?.isDefaultSrc,
                                isDefaultDst: addressTypeRadio !== 'srcAddress' ? true : item?.isDefaultDst,
                            }, true);
                        }}
                        style={{ color: uiTheme.primaryColor, marginRight: 8 }}
                    />
                )}
            </>
        );
    };

    const renderMarkAsDefault = (item: PickupAddress) => {
        if (defaultMultipleLimit) {
            return renderMarkAsDefaultMultiple(item);
        }
        // eslint-disable-next-line no-underscore-dangle
        if (defaultAddress._id === item.id) {
            return (
                <Button
                    style={{ cursor: 'default' }}
                    shape="round"
                >
                    Default
                </Button>
            );
        }
        return (
            <BookOutlined
                onClick={(e) => {
                    Helper.stops(e as any);
                    saveDefaultAddress(item);
                }}
                style={{ color: uiTheme.primaryColor, marginRight: 8 }}
            />
        );
    };
    const showaddcode = (item: PickupAddress) => {
        return (
            <span style={{ display: 'flex', fontWeight: 'bold' }}>
                <span>{item?.addressCode}</span>
                <span style={{ margin: '0px 6px 0px 4px', fontWeight: 'bold' }}> | </span>
            </span>
        );
    };
    const updateFormData = (newData: any) => {
        setFormData(
            {
                ...formData,
                previousSelectedAddress: {
                    ...formData.previousSelectedAddress,
                    [pluginAddressType]: newData,
                },
            },
        );
    };

    const renderCheckbox = (item: PickupAddress, key: string) => {
        const showEditButtons = !['oldDstAddress', 'oldSrcAddress'].includes(item?.id);
        const isEditEnabled = (item?.clientId === master?.Customer?.id) && !master?.properties?.disable_address_book_edit;
        return (
            <Checkbox
                key={item.id}
                checked={isCurrentAddressSelected(key, item)}
                className={classes.checkbox}
            >
                <div className={classes.addressName}>
                    <span style={{ display: 'flex' }}>
                        {showAddressCode && item?.addressCode ? showaddcode(item) : null}
                        <span style={{ display: 'inline-block', fontWeight: 'bold' }}>
                            {item.name || ''}
                        </span>
                    </span>
                    {
                        showEditButtons ? (
                            <div style={{ justifyContent: 'flex-end' }}>
                                {allowDefaultSourceAddress ? renderMarkAsDefault(item) : null}
                                {renderParentAddress(!isEditEnabled)}
                                {renderEdit(item, key, isEditEnabled)}
                                {renderDelete(item, isEditEnabled)}
                            </div>
                        ) : (
                            <div style={{ justifyContent: 'flex-end' }}>
                                {renderEdit(item, key, isEditEnabled)}
                            </div>
                        )
                    }
                </div>
            </Checkbox>
        );
    };

    const renderLine = () => {
        return (
            <div className={classes.line} />
        );
    };

    const handlePagination = (nextOrPrev: NextOrPrev) => {
        const { currentPageNumber } = pagination;
        let newPageNumber = currentPageNumber;
        switch (nextOrPrev) {
            case 'first':
                newPageNumber = 1;
                break;
            case 'next':
                newPageNumber = currentPageNumber + 1;
                break;
            case 'prev':
                newPageNumber = currentPageNumber - 1;
                break;
            default:
                break;
        }
        const newPagination: Pagination = {
            ...pagination,
            nextOrPrev,
            currentPageNumber: newPageNumber,
        };
        setPagination(newPagination as Pagination);
        setToggle(!toggle);
    };

    const renderPaginator = () => {
        return (
            <Paginator
                currentPageNumber={pagination?.currentPageNumber}
                isNextPresent={pagination?.isNextPresent}
                onNextClick={() => handlePagination('next')}
                onPrevClick={() => handlePagination('prev')}
                onFirstClick={() => handlePagination('first')}
            />

        );
    };

    const renderCreateAddressButton = (key: string) => {
        return (
            <>
                <Button
                    type="link"
                    onClick={() => {
                        setAddressType({
                            ...addressType,
                            [key]: 'createnew',
                        });
                        setEditAddress(undefined);
                        form.setFieldsValue({
                            [`addressOption${key}`]: 'createnew',
                        });
                        setCreatingAddress(true);
                    }}
                >
                    +
                    {t('add_new_address')}
                </Button>
            </>
        );
    };

    const renderSelectDefaultAddress = (key: string) => {
        // eslint-disable-next-line no-underscore-dangle
        if (!defaultAddress?._id) return null;
        if (defaultMultipleLimit) return null;
        return (
            <Button
                type="link"
                onClick={() => {
                    // eslint-disable-next-line no-underscore-dangle
                    const item: PickupAddress | undefined = addressList[key].find((add) => add.id === defaultAddress?._id);
                    if (item) {
                        setSelected({
                            ...selectedAddress,
                            [key]: item,
                        });
                        form.setFieldsValue({
                            [key]: item,
                        });
                        setFormData({
                            ...formData,
                            [key]: item,
                        });
                    }
                }}
            >
                {t('select_default_address')}
            </Button>
        );
    };

    const renderAddressSearch = (key: string) => {
        return (
            <div className={classes.searchAddress}>
                { addressTypeRadio === 'billToDetails'
                    ? (
                        <Checkbox
                            checked={billToSame}
                            onChange={(e) => {
                                setBillToSame(e.target.checked);
                                setFormData({
                                    ...formData,
                                    billToSame: e.target.checked,
                                });
                                if (e.target.checked) {
                                    setSelected({
                                        ...selectedAddress,
                                        billToDetails: formData.dstAddress,
                                    });
                                    setFormData({
                                        ...formData,
                                        billToDetails: formData.dstAddress,
                                    });
                                    form.setFieldsValue({
                                        billToDetails: formData.dstAddress,
                                    });
                                }
                            }}
                            style={{
                                marginLeft: 8,
                                marginRight: 'auto',
                            }}
                        >
                            {t('use_same_as_delivery_address')}
                        </Checkbox>
                    )
                    : null}
                {renderPaginator()}
                {master?.properties?.disable_address_book_edit ? null : renderCreateAddressButton(key)}
                {allowDefaultSourceAddress ? renderSelectDefaultAddress(key) : null}
                <Input
                    placeholder={t('search_for_addresses')}
                    type="search"
                    onChange={(e) => setSearchString({
                        ...searchString,
                        [key]: e.target.value,
                    })}
                    style={{ width: 200 }}
                />
            </div>
        );
    };

    const prettyPrintedNumber = (num: string) => {
        return num;
    };

    const renderAddressDetails = (item: PickupAddress) => {
        const customerCode = window.localStorage.getItem('userCode');
        return (
            <div className={classes.addressLineDetails}>
                <div style={{ minWidth: '30%' }}>
                    {item.addressLine1 || ''}
                    {item.addressLine1 ? ', ' : ''}
                    {item.addressLine2 || ''}
                </div>
                <div style={{
                    minWidth: '30%',
                    marginLeft: isRTL ? undefined : '10%',
                    marginRight: isRTL ? '10%' : undefined,
                }}
                >
                    {item.pincode || ''}
                    {item.pincode && item.cityName ? ' - ' : ''}
                    {item.cityName || ''}
                    <br />
                    {item.stateName || ''}
                    {item.stateName ? ', ' : ''}
                    {item.countryName || ''}
                </div>
                <div style={{ minWidth: '30%', textAlign: 'right' }}>
                    {item.phone
                        ? (
                            <>
                                {prettyPrintedNumber(item.phone)}
                                <br />
                            </>
                        ) : null}
                    {item.alternatePhone
                        ? (
                            <>
                                {prettyPrintedNumber(item.alternatePhone)}
                                <br />
                            </>
                        ) : null}
                    {item.email ? item.email : null}
                    <b>{`${item.locationId || ''}`}</b>
                    <br />
                    <b>{item.locationId ? item.locationCode || `${customerCode}_${item.locationId}` : ''}</b>
                </div>
            </div>
        );
    };

    const renderAddressLine = (item: PickupAddress, key: string) => {
        let className = classes.addressLine;
        const isCurrentSelected = isCurrentAddressSelected(key, item);
        if (isCurrentSelected) {
            className = [classes.addressLineSelected, classes.addressLine].join(' ');
        }
        return (
            <div
                key={item.id}
                onClick={(e) => {
                    if (addressTypeRadio === 'billToDetails') {
                        setBillToSame(false);
                        setFormData({
                            ...formData,
                            billToSame: false,
                        });
                    }
                    Helper.stops(e as any);
                    // remove selection if clicked on same row
                    if (isCurrentSelected) {
                        setSelected({
                            ...selectedAddress,
                            [key]: null,
                        });
                        form.setFieldsValue({
                            [key]: undefined,
                        });
                        setFormData({
                            ...formData,
                            [key]: undefined,
                        });
                    } else {
                        // save address
                        setSelected({
                            ...selectedAddress,
                            [key]: item,
                        });
                        form.setFieldsValue({
                            [key]: item,
                        });
                        setFormData({
                            ...formData,
                            [key]: item,
                        });
                    }
                }}
                className={className}
            >
                {renderCheckbox(item, key)}
                {renderAddressDetails(item)}
            </div>
        );
    };

    const renderAddressList = (key: string) => {
        if (loading) {
            return <Loader zIndex={10} />;
        }
        return (
            <div className={classes.addressList}>
                {addressList[key].map((item: PickupAddress) => renderAddressLine(item, key))}
            </div>
        );
    };

    const renderExisting = (key: string) => {
        return (
            <>
                {renderAddressSearch(key)}
                {renderLine()}
                {renderAddressList(key)}
                {renderLine()}
            </>
        );
    };

    const renderAddressFormUtil = (key: string) => {
        if (addressType[key] === 'createnew') {
            return (
                <>
                    {autofillAddressFieldsBasedOnAddressHierarchy
                        ? (
                            <CreateAddress2
                                editData={editAddress}
                                updateFormData={updateFormData}
                                page={page}
                                onClose={() => {
                                    setAddressType({
                                        ...addressType,
                                        [key]: 'saved',
                                    });
                                    setEditAddress(undefined);
                                    form.setFieldsValue({
                                        [`addressOption${key}`]: 'saved',
                                    });
                                    setCreatingAddress(false);
                                }}
                                phoneRegex={phoneRegex}
                                isInternationalAddress={
                                    formData.consignmentCategory === 'international' && addressTypeRadio !== 'srcAddress'
                                    // || (editAddress?.isInternational || false)
                                }
                                countryWisePhoneRegex={countryWisePhoneRegex}
                                showBackButton
                            />
                        ) : (
                            <CreateAddress
                                editData={editAddress}
                                updateFormData={updateFormData}
                                page={page}
                                onClose={() => {
                                    setAddressType({
                                        ...addressType,
                                        [key]: 'saved',
                                    });
                                    setEditAddress(undefined);
                                    form.setFieldsValue({
                                        [`addressOption${key}`]: 'saved',
                                    });
                                    setCreatingAddress(false);
                                }}
                                phoneRegex={phoneRegex}
                                isInternationalAddress={
                                    formData.consignmentCategory === 'international' && addressTypeRadio !== 'srcAddress'
                                    // || (editAddress?.isInternational || false)
                                }
                                countryWisePhoneRegex={countryWisePhoneRegex}
                                showBackButton
                            />
                        )}
                </>
            );
        }
        return renderExisting(key);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const renderAddressForm = (key: string) => {
        return (
            <>
                {renderAddressFormUtil(key)}
            </>
        );
    };


    const complete = () => {
        return (
            <CheckCircleFilled
                style={{
                    color: '#27B479',
                    fontSize: 24,
                    margin: '0px 10px',
                }}
            />
        );
    };

    const inComplete = () => {
        return (
            <ExclamationCircleFilled
                style={{
                    color: '#EA2626',
                    fontSize: 24,
                    margin: '0px 10px',
                }}
            />
        );
    };

    const completeIncompleteIcon = (key: string) => {
        const active = key === addressTypeRadio;
        if (!addressListVisited[key] || active) {
            return (
                <div
                    className={classes.tabIcon}
                    style={{
                        border: active ? `2px solid ${uiTheme.primaryColor}`
                            : '2px solid #CCCCCC',
                        backgroundColor: active ? uiTheme.primaryColor : '#FFF',
                    }}
                >
                    <div className={classes.filledCircle} />
                </div>
            );
        }
        if (!selectedAddress[key]) {
            return inComplete();
        }
        return complete();
    };

    const renderConsigneeCompanyName = () => {
        return (
            <div className={classes.consigneeDetailsInput}>
                <Form.Item
                    name="consigneeCompanyName"
                >
                    <Input
                        placeholder={t('renderConsigneeCompanyName')}
                        type="input"
                        onChange={(e) => form.setFieldsValue({
                            consigneeCompanyName: e.target.value,
                        })}
                        style={{ width: 200, height: 32 }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderReceiverVATNumber = () => {
        return (
            <div className={classes.consigneeDetailsInput}>
                <Form.Item
                    name="receiverVATNumber"
                >
                    <Input
                        placeholder={t('receiver_vat_number_placeholder')}
                        type="input"
                        onChange={(e) => form.setFieldsValue({
                            receiverVATNumber: e.target.value,
                        })}
                        style={{ width: 200, height: 32 }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderDestinationType = () => {
        const consigneeTypeOptions = [t('consignee_type_individual'), t('consignee_type_business')];
        return (
            <div className={classes.consigneeDetailsInput}>
                <Form.Item
                    name="destinationType"
                    rules={isDestinationTypeRequired ? [formRuleRequired] : undefined}
                >
                    <Radio.Group
                        options={consigneeTypeOptions}
                        onChange={(e) => form.setFieldsValue({
                            destinationType: e.target.value,
                        })}
                        style={{ width: 300, height: 32 }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderConsigneeDetails = (FieldIcon: React.ElementType, label: string) => {
        return (
            <>
                <div className={classes.box}>
                    {renderRowDescription(FieldIcon, label)}
                    <div className={classes.consigneeDetails}>
                        <div className={classes.flexRow}>
                            {fieldsToShowInternational.consigneeCompanyName ? (
                                <div className={classes.flexColumn}>
                                    <div style={{ marginBottom: 8 }}>
                                        {t('consignee_company_name')}
                                    </div>
                                    {renderConsigneeCompanyName()}
                                </div>
                            ) : null}
                            {fieldsToShowInternational.receiverVATNumber ? (
                                <div className={classes.flexColumn}>
                                    <div style={{ marginBottom: 8 }}>
                                        {t('receiver_vat_number')}
                                    </div>
                                    {renderReceiverVATNumber()}
                                </div>
                            ) : null}
                            {fieldsToShowInternational.destinationType ? (
                                <div className={classes.flexColumn}>
                                    <div style={{ marginBottom: 8 }}>
                                        {t('consignee_type')}
                                        {isDestinationTypeRequired ? '*' : ''}
                                    </div>
                                    {renderDestinationType()}
                                </div>
                            ) : null}
                        </div>
                        { renderLine() }
                    </div>
                </div>
            </>
        );
    };

    const renderAddress = () => {
        const field = { ...AddressTypes };
        const showAlternate = showAlternateAddress
            && addressKey === 'dstAddress' && formData.consignmentCategory === 'domestic';
        if (addressKey === 'dstAddress') {
            if (showAlternate) {
                field.options = field.options?.filter((option) => option.value !== 'srcAddress');
            } else {
                field.options = field.options?.filter((option) => (
                    option.value === 'dstAddress' || option.value === 'billToDetails'
                ));
            }
            if (formData.consignmentCategory !== 'international') {
                field.options = field.options?.filter((option) => option.value !== 'billToDetails');
            }
        } else {
            field.options = field.options?.filter((option) => option.value === 'srcAddress');
        }
        if (Array.isArray(field.options) && field.options.length > 0) {
            field.options.forEach((item: any) => {
                /* eslint-disable no-param-reassign */
                item.label = t(item.value);
            });
        }
        return (
            <>
                { formData.consignmentCategory === 'international' && page === 'Consignee'
                    ? renderConsigneeDetails(AddressIcon, t(`${(page || 'Consignee').toLowerCase()}_details`))
                    : null }
                <div className={classes.box}>
                    { formData.consignmentCategory !== 'international'
                        ? (renderRowDescription(AddressIcon, pageTitle))
                        : <div className={classes.boxTitle} /> }
                    <div className={classes.addressMenu}>
                        {field.options?.map((address) => {
                            return (
                                <div
                                    key={address.value}
                                    onClick={() => {
                                        setAddressTypeRadio(address.value);
                                        setAddressTypeVisited({ ...addressTypeVisited, [address.value]: true });
                                        setAddressListVisited({
                                            ...addressListVisited,
                                        });
                                    }}
                                    className={
                                        address.value === addressTypeRadio
                                            ? classes.addressItemSelected : classes.addressItem
                                    }
                                >
                                    {completeIncompleteIcon(address.value)}
                                    {address.label}
                                </div>
                            );
                        })}
                    </div>
                    <div className={classes.addressDetails}>
                        <div className={classes.addressBox}>
                            {addressKey === 'dstAddress'
                                ? renderAddressForm(addressTypeRadio) : renderAddressForm(addressKey)}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className={classes.main}>
            {renderAddress()}
        </div>
    );
};
const mapStateToProps = (state: ReduxStore) => {
    return {
        autofillAddressFieldsBasedOnAddressHierarchy: state?.master
            ?.parts_to_show?.autofill_address_fields_based_on_address_hierarchy,
        uiTheme: state.uiTheme,
    };
};
const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};


export default withStyles(stepTwoStyles)(GenericHoc(hocConfig)(StepTwo));
