import React, { useState } from 'react';
import { RouteChildrenProps, useHistory, useLocation } from 'react-router-dom';
import { loginStyles } from './sign-in.styles';
import { login } from '../../network/common.api';
import { StylesProps } from '../../theme/jss-types';
import {
    getDefaultLandingRoute,
    getForgotPasswordRoute,
    getSuperUserLoginRoute,
} from '../../routing/routing-helper';
import { ReduxStore } from '../../reducers/redux.types';
import {
    Button,
    Form,
    Input,
    message,
} from 'antd';
import { HocOptions } from '../common/generic-hoc.types';
import GenericHoc from '../common/generic-hoc';
import { LOGO_URL, CAPTCHA_SITE_KEY } from 'library/globals';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { get } from 'lodash';
import Helper from 'library/Helper';
import { CryptoUtils } from 'library/crypto-utils';
import scriptJs from 'scriptjs';
import Loader from '../common/Loader';


interface LoginProps extends StylesProps<ReturnType<typeof loginStyles>>,
    RouteChildrenProps {
    primaryColor: string;
    secondryColor: string;
    disableForgotPassword: boolean;
}

const isCaptchaConfigPresent = () => {
    return (!!CAPTCHA_SITE_KEY && (CAPTCHA_SITE_KEY.trim() !== ''));
};

const SignIn = (props: LoginProps): React.ReactElement => {
    const history = useHistory();
    const {
        classes,
        primaryColor,
        secondryColor,
        disableForgotPassword,
    } = props;
    const [disableButton, setDisableButton] = useState(false);
    // const [keepMeSignedIn, setKeepMeSignedIn] = useState(false);
    const [isCaptchaLoaded, setIsCaptchaLoaded] = useState(false);
    const [isEmployee, setIsEmployee] = useState(false);
    const { t } = useTranslation();
    const location = useLocation();

    const saveLoginResult = async (result: any) => {
        const storage = window.localStorage;
        const defaultLandingUrl = result.data?.config?.default_landing_url || '/consignments';
        // since projectx drives customer portal persona
        // we assume childcustomer is loggend in if customer.id and customerUserDetails.customer_id are different
        let isChildCustomer = false;
        if (result.data?.Customer.id && result.data?.CustomerUserDetails?.customer_id) {
            isChildCustomer = result.data.Customer?.id !== result.data.CustomerUserDetails?.customer_id;
        }

        storage.setItem('organisationId', result.data.organisation_id);
        if (primaryColor === undefined) {
            storage.setItem('primaryColor', '#082E78');
            storage.setItem('secondryColor', '#ED1C24');
        } else if (secondryColor === undefined) {
            storage.setItem('primaryColor', primaryColor);
            storage.setItem('secondryColor', primaryColor);
        } else {
            storage.setItem('primaryColor', primaryColor);
            storage.setItem('secondryColor', secondryColor);
        }
        storage.setItem('userId', result.data.Customer.id);
        storage.setItem('authToken', result.data.access_token.id);
        storage.setItem('customerUserId', result?.data?.CustomerUserDetails?.id);
        storage.setItem(
            'logoUrl',
            result.data.logo_url
            || LOGO_URL,
        );
        storage.setItem('username', result.data.Customer.name);
        storage.setItem('userCode', result.data.Customer.code);
        storage.setItem('childCustomerId', result.data.Customer.id);
        storage.setItem('childCustomerCode', result.data.Customer.code);
        storage.setItem('childAccessToken', result.data.access_token.id);
        storage.setItem('isChildCustomer', isChildCustomer.toString());
        storage.setItem('childCustomerUserId', result?.data?.CustomerUserDetails?.id);
        storage.setItem('parentId', result.data.Customer.parent_id || null);
        storage.setItem('redirectionTime', result.data.redirection_time);
        storage.setItem('employeeAuthToken', result.data?.employee?.access_token);
        storage.setItem('employeeCode', result.data?.employee?.employee_code);
        storage.setItem('employeeId', result.data?.employee?.id);
        storage.setItem('defaultLandingUrl', defaultLandingUrl);

        const faviconUrl = get(result, 'data.config.favicon_url');
        Helper.changeFavicon(faviconUrl);
    };

    const handleOldCPLogin = async () => {
        const urlParams = new URLSearchParams(history.location.search);
        const unencryptedParams: any = urlParams.get('key');
        const encryptedParams: any = urlParams.get('ekey');
        if (!unencryptedParams && !encryptedParams) {
            return;
        }
        let loginInfoStr = '';
        if (encryptedParams) {
            loginInfoStr = await CryptoUtils.decryptWithSessionKey(encryptedParams);
        } else {
            loginInfoStr = Buffer.from(unencryptedParams, 'base64').toString();
        }

        const loginInfo = JSON.parse(loginInfoStr);
        saveLoginResult({
            data: {
                logo_url: loginInfo.logoUrl,
                organisation_id: loginInfo.organisationId,
                Customer: {
                    name: loginInfo.username,
                    code: loginInfo.userCode,
                    id: loginInfo.userId,
                },
                access_token: {
                    id: loginInfo.authToken,
                },
                redirection_time: new Date().valueOf(),
                employee: {
                    access_token: loginInfo.employeeAuthToken,
                    id: loginInfo.employeeId,
                    employee_code: loginInfo.employeeCode,
                },
                CustomerUserDetails: {
                    id: loginInfo.customerUserId,
                    customer_id: loginInfo.customerId,
                },
                config: {
                    default_landing_url: loginInfo.defaultLandingUrl,
                },
            },
        });
        history.push(getDefaultLandingRoute());
    };

    React.useEffect(() => {
        handleOldCPLogin();
    }, []);

    React.useEffect(() => {
        if (isCaptchaConfigPresent()) {
            setIsCaptchaLoaded(false);
            scriptJs(`https://www.google.com/recaptcha/api.js?render=${CAPTCHA_SITE_KEY}`, () => {
                setIsCaptchaLoaded(true);
            });
        }
    }, []);

    React.useEffect(() => {
        if (location.pathname === getSuperUserLoginRoute()) {
            setIsEmployee(true);
        }
    }, [location.pathname]);

    const onFinish = async (values: { email: string; password: string, customerCode?: string }) => {
        const getCaptchaTokenPromise: any = () => new Promise((resolve) => {
            if ((!isCaptchaLoaded) || (!(window as any)?.grecaptcha)) {
                resolve({ success: false, errorString: 'Failed to load reCaptcha' });
                return;
            }
            (window as any)?.grecaptcha.ready(async () => {
                try {
                    const token = await (window as any)?.grecaptcha.execute(
                        CAPTCHA_SITE_KEY, { action: 'submit' },
                    );
                    resolve({ success: true, token });
                } catch (err) {
                    resolve({ success: false, err });
                }
            });
        });

        setDisableButton(true);

        const { email, password, customerCode } = values;
        const body: any = {
            username: email,
            password,
            // keepMeSignedIn,
            is_employee: isEmployee,
            customer_code: customerCode,
        };

        const encryptedPassword = await CryptoUtils.encryptRSA('authentication', password);
        if (encryptedPassword) {
            body.pwd = encryptedPassword;
            delete body.password;
        }

        if (isCaptchaConfigPresent()) {
            const { success: isValidToken, token, errorString } = await getCaptchaTokenPromise();
            if (!isValidToken || !token) {
                message.error(errorString || 'Invalid Recaptcha token');
                setDisableButton(false);
                return;
            }
            body.captcha_validation_token = token;
        }
        const result = await login(body);
        setDisableButton(false);

        if (!result?.isSuccess) {
            message.error(result?.errorMessage || 'Something went wrong! Please try again');
        } else {
            result.data.redirection_time = new Date().valueOf();
            saveLoginResult(result);
            const isLanguageChangeAllowed = result?.data?.config?.customer_portal_ops_config?.language_config
                ?.config_value?.is_language_change_allowed || false;
            const defaultLanguage = result?.data?.config?.customer_portal_ops_config?.language_config
                ?.config_value?.default_language_customer_portal || 'en';
            if (isLanguageChangeAllowed && !i18n.language) {
                i18n.changeLanguage(defaultLanguage, (err: any) => {
                    if (err) {
                        message.error(err);
                        return;
                    }
                    message.success('Language changed successfully');
                    window.location.reload();
                });
            }
            const postLoginPath = getDefaultLandingRoute();
            window.location.href = postLoginPath;
        }
    };

    const handleForgotPassword = () => {
        history.push(getForgotPasswordRoute());
    };

    const renderPassword = () => {
        return (
            <div>
                <span className={classes.labelForInput}>
                    {t('password')}
                </span>
                <Form.Item
                    className={classes.emailLabel}
                    name="password"
                    wrapperCol={{ span: 20 }}
                    colon={false}
                    rules={[{
                        required: true,
                        message: t('login_error_message'),
                    }]}
                >
                    <Input.Password
                        className={classes.emailInput}
                        placeholder={t('login_password_placeholder')}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderUsername = () => {
        return (
            <div>
                <span className={classes.labelForInput}>
                    {t('username')}
                </span>
                <Form.Item
                    wrapperCol={{ span: 20 }}
                    className={classes.emailLabel}
                    name="email"
                    colon={false}
                    rules={[{
                        required: true,
                        message: t('login_email_error'),
                    }]}
                >
                    <Input
                        className={classes.emailInput}
                        placeholder={t('login_username_placeholder')}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderCustomerCode = () => {
        if (!isEmployee) {
            return null;
        }
        return (
            <div>
                <span className={classes.labelForInput} style={{ width: 75 }}>
                    {t('customer_code')}
                </span>
                <Form.Item
                    wrapperCol={{ span: 24 }}
                    className={classes.emailLabel}
                    name="customerCode"
                    colon={false}
                    rules={[{
                        required: true,
                        message: t('login_customer_code_error'),
                    }]}
                >
                    <Input
                        className={classes.emailInput}
                        placeholder={t('login_customer_code_placeholder')}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderForgot = () => {
        if (disableForgotPassword) {
            return null;
        }
        return (
            <div className={classes.forgotPassDiv}>
                <div
                    className={classes.forgotPassword}
                    role="link"
                    tabIndex={0}
                    onClick={handleForgotPassword}
                    onKeyPress={handleForgotPassword}
                >
                    {t('forgot_password')}
                    ?
                </div>
            </div>
        );
    };

    const renderLoginButton = () => {
        return (
            <Form.Item
                wrapperCol={{ span: 20 }}
                style={{
                    textAlign: 'left',
                    marginBottom: '16px',
                }}
            >
                <Button
                    className={classes.continueButton}
                    style={{ margin: '0 16px 16px 0', width: '100%' }}
                    type="primary"
                    htmlType="submit"
                    loading={disableButton}
                >
                    {t('login_heading')}
                </Button>
                {/* <Checkbox
                    onChange={(e) => {
                        setKeepMeSignedIn(e.target.checked);
                    }}
                >
                    {t('login_keep_me_signed_in')}
                </Checkbox> */}
            </Form.Item>
        );
    };

    const renderSignInForm = () => {
        return (
            <Form
                wrapperCol={{ span: 20 }}
                onFinish={onFinish}
                style={{ marginLeft: '15%' }}
            >
                {renderUsername()}
                {renderPassword()}
                {renderCustomerCode()}
                {renderLoginButton()}
                {renderForgot()}
            </Form>
        );
    };

    if (isCaptchaConfigPresent() && !isCaptchaLoaded) {
        return <Loader zIndex={5} />;
    }

    return (
        <div className={classes.signInForm}>
            <div className={classes.welcomeMsg}>
                {t('customer_portal_heading')}
            </div>
            <div
                className={[classes.formHeader, classes.workspaceHeader].join(' ')}
            >
                {t('login_heading')}
            </div>
            {renderSignInForm()}
        </div>
    );
};
const mapStateToProps = (state: ReduxStore) => {
    return {
        primaryColor: state.uiTheme?.primaryColor,
        secondryColor: state.uiTheme?.secondryColor,
        uiTheme: state.uiTheme,
        disableForgotPassword: state?.master?.parts_to_show?.disable_edit_password_from_customer_portal,
    };
};



const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: loginStyles,
    },
    connectRouter: true,
};

const Login = GenericHoc(hocConfig)(SignIn);
export default Login;
